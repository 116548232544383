import React from 'react';
import { useParams} from "react-router";
import {MoreInfo} from './moreInfo'
import {PrivacyPolicy} from './privacyPolicy'
import {TranslatedText} from "../lang";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../assets/logo.png'
import styled, { css } from 'styled-components';
import * as ES6Promise from 'es6-promise';
import {ConsentFormType,} from '../common/types';
ES6Promise.polyfill();

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Tillotts AB
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const Form = styled.form`
    ${props =>
    props.submitted &&
    css`
                opacity: 0;
                height 0;
                display: none;
            `}
`
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    hidden: {
        display: 'none'
    }
}));

export const ConsentFormView = () => {
    const params = useParams();
    let queryString: ConsentFormType = JSON.parse(atob(params.consentObject));
    const classes = useStyles();
    const [consentForm] = React.useState<ConsentFormType>(queryString);
    const [translatedText] = React.useState(TranslatedText(parseInt(queryString.country), queryString.responsible));
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img alt="Tillotts Logo" src={logo}/>
                <hr/>
                <Form className={classes.form}>
                    <Typography component="h2" variant="body1">
                        {translatedText.welcome_title} {consentForm.name}
                        <br/>
                        {translatedText.welcome_body}
                        <br/>
                        {translatedText.best_regards}
                        <br/>
                        {consentForm.responsible}
                    </Typography>
                    <br/>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label={translatedText.label_primary_email}
                                name="email_primary"
                                autoComplete="email"
                                disabled={true}
                                value={consentForm.email_primary}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="email"
                                label={translatedText.label_second_email}
                                name="email_second"
                                autoComplete="email"
                                disabled={true}
                                value={consentForm.email_second}
                            />
                        </Grid>
                        <Grid item xs={12} className={consentForm.country !== '3' ? classes.hidden : ""}>
                            <TextField
                                fullWidth
                                id="phone"
                                label={translatedText.label_phone}
                                name="phone"
                                autoComplete="phone"
                                disabled={true}
                                value={consentForm.phone}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="mobile"
                                label={translatedText.label_mobile}
                                name="mobile"
                                autoComplete="mobile"
                                disabled={true}
                                value={consentForm.mobile}
                            />
                        </Grid>
                        <span>
                            {translatedText.required_text}
                        </span>
                        <Grid item xs={12}>
                            <MoreInfo country={parseInt(consentForm.country)}></MoreInfo>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox disabled={true} defaultChecked value="consent" color="primary" />}
                                label={translatedText.consent}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox disabled={true} defaultChecked value="tos" color="primary" />}
                                label={
                                    <div>
                                        <span>{translatedText.privacy_policy} </span>
                                        <PrivacyPolicy country={parseInt(consentForm.country)}></PrivacyPolicy>
                                    </div>}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={true}
                    >
                        {translatedText.label_submit}
                    </Button>
                </Form>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}
