import React, {useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../assets/logo.png'
import axios from "axios";
import { useParams} from "react-router";
const {REACT_APP_ENDPOINT} = process.env;

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                Tillotts AB
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    hidden: {
        display: 'none'
    }
}));

export const AssignConsent = () => {
    const params = useParams();
    const classes = useStyles();
    const [confirmationTextSuccess, setConfirmationTextSuccess] = React.useState({
        line1: '', line2: '', line3: '', line4: '', line5: '', line6: '', line7: ''});
    const [confirmationText, setConfirmationText] = React.useState({line1: '', line2: '', line3: ''});


    useEffect( () => {
        (async () => {
            try {
                if (!params.consentId || !params.userId)
                    throw 'Missing arguments, please inform Joakim - ' + JSON.stringify(params)
                const res = await axios.post(`${REACT_APP_ENDPOINT}consent/${params.consentId}/${params.userId}`);
                if (res.data.result.status === 'Error') {
                    setConfirmationText({line1: `You were not fast enough, this consent is already assigned to ${res.data.result.user} :)`,
                        line2: `Best Regards`,
                        line3: 'eConnect'})
                } else {
                    setConfirmationTextSuccess({
                        line1: `Sweet, this consent is now assigned to you and a confirmation e-mail will shortly be sent to the customer with you as a sender.`,
                        line2: `The next step (when you have time), is to follow the steps below`,
                        line3: '1. Open eConnect and find the customer',
                        line4: '2. Add the e-mail address',
                        line5: '3. After that, eConnect will automatically assign this consent to the customer and you will be notified after a few seconds if the consent was found',
                        line6: 'Best regards',
                        line7: 'eConnect'})
                }
            } catch (e: any) {
                setConfirmationText({line1: `Consent was not assigned to you due to => ${e.toString()}`,
                    line2: `Best Regards`,
                    line3: 'eConnect'})
                //errorText();
                console.error(e.toString());
            }
        })()
    }, []);

    return (
        <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className={classes.paper}>
                <img alt="Tillotts Logo" src={logo}/>
                <hr/>
                {
                    confirmationText.line1 ?
                        <Typography component="h2" variant="h6">
                            {confirmationText.line1}
                            <br/>
                            <br/>
                            {confirmationText.line2}
                            <br/>
                            {confirmationText.line3}
                            <br/>
                        </Typography>
                        :''
                }
                {
                    confirmationTextSuccess ?
                        <Typography component="h2" variant="h6">
                            {confirmationTextSuccess.line1}
                            <br/>
                            <br/>
                            {confirmationTextSuccess.line2}
                            <br/>
                            <br/>
                            {confirmationTextSuccess.line3}
                            <br/>
                            {confirmationTextSuccess.line4}
                            <br/>
                            {confirmationTextSuccess.line5}
                            <br/>
                            <br/>
                            {confirmationTextSuccess.line6}
                            <br/>
                            {confirmationTextSuccess.line7}
                        </Typography>
                        :''
                }
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}
