import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import PropTypes from "prop-types";
import {TranslatedText, translatedTexts} from "../lang";
import {Link} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            position: 'relative',
        },
        title: {
            marginLeft: theme.spacing(2),
            flex: 1,
        },
        iframe:  {
            transition: 'opacity .5s ease',
            opacity: '1',
            display: 'block',
            margin: 'auto',
            width: '100%',
            height: '100%'
        }
    }),
);
const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export const PrivacyPolicy = ({country}) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const textTranslated: translatedTexts = TranslatedText(country);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Link href="#" onClick={handleClickOpen}>
                {textTranslated.privacy_policy_url_text}
            </Link>
            <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {textTranslated.privacy_policy_url_text.toUpperCase()}
                        </Typography>
                        <Button autoFocus color="inherit" onClick={handleClose}>
                            {textTranslated.label_close}
                        </Button>
                    </Toolbar>
                </AppBar>
                <iframe title={textTranslated.privacy_policy_url_text} id='iframus' className={classes.iframe} src={textTranslated.privacy_policy_url}></iframe>
            </Dialog>
        </React.Fragment>
    );
}
PrivacyPolicy.propTypes = {
    country: PropTypes.number.isRequired
}
