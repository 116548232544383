import React, {useEffect} from 'react';
import {MoreInfo} from './moreInfo'
import {PrivacyPolicy} from './privacyPolicy'
import { useParams} from "react-router";
import {TranslatedText} from "../lang";
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import logo from '../assets/logo.png'
import styled, { css } from 'styled-components';
import * as ES6Promise from 'es6-promise';
import {ConsentFormType} from '../common/types';
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
ES6Promise.polyfill();
const axios = require('axios');
const {REACT_APP_ENDPOINT} = process.env;

const getCountryFromURL = () => {
    const titleText = {
        "se": "Lämna ditt samtycke",
        "no": "Gi ditt samtykke",
        "dk": "Giv dit samtykke",
        "fi": "Anna suostumuksesi"
    };

    const country = window.location.hostname.split('.')?.pop();
    if (country && titleText[country]) {
        document.title = titleText[country];
    }
    switch (country) {
        case 'se':
            return 1;
        case 'no':
            return 2;
        case 'dk':
            return 3;
        case 'fi':
            return 4;
        default:
            return 1;
    }
}
const emptyConsentForm: ConsentFormType = {
    uuid: '',
    name: '',
    responsible: 'Tillotts',
    customerid: 0,
    firstname: '',
    lastname: '',
    site: '',
    specialty: '',
    specialty_other: '',
    email_primary: '',
    email_second: '',
    mobile: '',
    phone: '',
    country: `${getCountryFromURL()}`,
    date:'',
    tos:false,
    consent:false,
    origin: window.location.origin
};

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.tillotts.com/">
                Tillotts AB
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}
const Form = styled.form`
    ${props =>
        props.submitted &&
        css`
                opacity: 0;
                height: 0;
                display: none;
            `}
`
const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    formControl: {
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    hidden: {
        display: 'none'
    }
}));
const currentdate = new Date();
const date = currentdate.getFullYear() + "-"
    + (currentdate.getMonth()+1)  + "-"
    + currentdate.getDate();
export const ConsentForm = () => {
    const params = useParams();

    const classes = useStyles();
    const [confirmationText, setConfirmationText] = React.useState({line1: '', line2: '', line3: ''});
    const [consentForm, setConsentForm] = React.useState<ConsentFormType>(emptyConsentForm);
    const [emailPrimaryError, setEmailPrimaryError] = React.useState(false);
    const [emailSecondError, setEmailSecondError] = React.useState(false);
    const [disableSubmit, setDisableSubmit] = React.useState(true);
    const [submitted, setSubmitted] = React.useState(false);
    const [translatedText, setTranslatedText] = React.useState(TranslatedText());
    const [hasOldLink, setHasOldLink] = React.useState(false);
    const [sessionId, setSessionId] = React.useState('');

    const confirmText = () => {
        setConfirmationText({line1: translatedText.confirmation_text,
            line2: translatedText.best_regards,
            line3: consentForm.responsible})
    }
    const errorText = () => {
        setConfirmationText({line1: `Oops, something went wrong when we tried to save your consent. Could I kindly ask you to try again in a couple of minutes?`,
            line2: 'Best regards',
            line3: consentForm.responsible});
    }

    const handleCheckBoxChange = (event: React.ChangeEvent<{ value: string; checked?: boolean }>) => {
        setConsentForm({...consentForm, [event.target.value]: event.target.checked});
    };
    const handleFieldChange = (event: React.ChangeEvent<{ value: string; name: string }>) => {
        setConsentForm({...consentForm, [event.target.name]: event.target.value});

    };
    const handleSpecialtyChange = (event) => {
        setConsentForm({...consentForm, specialty: event.target.value});

    };
    const handleEmailChange = (event: React.ChangeEvent<{ name: string; value: unknown }>) => {
        const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // @ts-ignore
        setConsentForm({...consentForm, [event.target.name]: event.target.value});
        if (!event.target.value && event.target.name === 'email_primary') {
            setEmailPrimaryError(true);
        } else if (!event.target.value && event.target.name !== 'email_primary'){
            setEmailSecondError(false);
        } else {
            if (re.test(String(event.target.value).toLowerCase())) {
                if (event.target.name === 'email_primary')
                    setEmailPrimaryError(false);
                else
                    setEmailSecondError(false);
            } else {
                if (event.target.name === 'email_primary')
                    setEmailPrimaryError(true);
                else
                    setEmailSecondError(true);
            }
        }

    };

    const handleSubmit = async () => {
        if (params.uuid) {
            let url = `${REACT_APP_ENDPOINT}updateConsentRequestItem/${params.uuid}`;
            consentForm.date = date;
            try {
                await axios.post(url, {
                    ...consentForm,
                    consentStatus: 'CONSENT_APPROVED_BY_CUSTOMER',
                    sessionId,
                });
                confirmText();
                setSubmitted(true);
            } catch (e: any) {
                errorText();
                console.error(e.toString());
            }
        } else {
            try {
                await axios.put(`${REACT_APP_ENDPOINT}consent`, {
                    payload: {
                        firstname: consentForm.firstname,
                        lastname: consentForm.lastname,
                        primaryEmail: consentForm.email_primary,
                        secondaryEmail: consentForm.email_second,
                        phone: consentForm.phone,
                        mobile: consentForm.mobile,
                        site: consentForm.site,
                        specialty: consentForm.specialty === translatedText.specialty_other ? consentForm.specialty_other : consentForm.specialty,
                        country: getCountryFromURL(),
                        consent: 'Approved',
                        origin: window.location.origin
                    },
                    sessionId,
                    action: 'SUBMIT_CONSENT'
                }
                );
                confirmText();
                setSubmitted(true);
            } catch (e: any) {
                errorText();
                console.error(e.toString());
            }
        }

    }
    const prepareEmptyForm = async () => {
        const session = await axios.get(`${REACT_APP_ENDPOINT}session`);
        setSessionId(session.data.sessionId);
        setTranslatedText(TranslatedText(getCountryFromURL(), 'Tillotts'));
    }
    const getItemFromDb = async (uuid) => {
        let url = `${REACT_APP_ENDPOINT}consentRequestItem/${uuid}`;
        try {
            const result = await axios.get(url);
            if (result.data.sessionId) {
                setSessionId(result.data.sessionId);
                result.data = result.data.consentRequest;
            }
            if ( !result.data ) {
                setSubmitted(true);
                setConfirmationText({line1: `Oops, something went wrong when we tried to get your prefilled consent form. Could I kindly ask you to contact your sales rep again??`,
                    line2: 'Best Regards',
                    line3: consentForm.responsible});
                return;
            }
            if ( result.data.uuid ) {
                setTranslatedText(TranslatedText(parseInt(result.data.country), result.data.responsible));
                setConsentForm(result.data);
                if ( result.data.consentStatus !== 'PENDING') {
                    setSubmitted(true);
                    setHasOldLink(true);
                }
            } else {
                setSubmitted(true);
                setConfirmationText({line1: `Oops, something went wrong when we tried to get your prefilled consent form. Could I kindly ask you to check your url?`,
                    line2: 'Best Regards',
                    line3: consentForm.responsible});
            }
        } catch (e: any) {
            console.error(e.toString());
            setSubmitted(true);
            setConfirmationText({line1: `Oops, something went wrong when we tried to get your prefilled consent form. Could I kindly ask you to contact your sales rep again??`,
                line2: 'Best Regards',
                line3: consentForm.responsible});
        }
    }
    useEffect(() => {
        const isSpecialtyFilled = (consentForm.specialty && consentForm.specialty!== translatedText.specialty_other) || (consentForm.specialty === translatedText.specialty_other && consentForm.specialty_other)
        if (params.uuid && !submitted && consentForm.email_primary && consentForm.tos && consentForm.consent && !emailSecondError && !emailPrimaryError) {
            setDisableSubmit(false);
        } else if(!params.uuid && !submitted && consentForm.email_primary && consentForm.tos && consentForm.consent && !emailSecondError && !emailPrimaryError && consentForm.firstname && consentForm.lastname && consentForm.site && isSpecialtyFilled) {
            setDisableSubmit(false);
        } else {
            setDisableSubmit(true);
        }
        if (hasOldLink) {
            setConfirmationText({line1: translatedText.old_link,
                line2: translatedText.best_regards,
                line3: consentForm.responsible})
        }
    }, [consentForm, emailPrimaryError, emailSecondError, translatedText, submitted, hasOldLink]);

    useEffect( () => {
        if (params.uuid) {
            getItemFromDb(params.uuid);
        } else {
            prepareEmptyForm();
        }

    }, [])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <img alt="Tillotts Logo" src={logo}/>
                <hr/>
                <Form className={classes.form} submitted={submitted}>
                    <Typography component="h2" variant="body1">
                        {translatedText.welcome_title} {consentForm.name}
                        <br/>
                        {translatedText.welcome_body}
                        <br/>
                        {translatedText.best_regards}
                        <br/>
                        {consentForm.responsible}
                    </Typography>
                    <br/>
                    <Grid container spacing={2}>
                        {
                            !params.uuid ?
                                <React.Fragment>
                                <Grid item xs={12}>
                                    <TextField
                                        id="firstname"
                                        required
                                        style={{width: '50%', paddingRight: '2%'}}
                                        label={translatedText.label_firstname}
                                        name="firstname"
                                        onChange={handleFieldChange}
                                        value={consentForm.firstname}
                                    />
                                    <TextField
                                        id="lastname"
                                        required
                                        style={{width: '50%'}}
                                        label={translatedText.label_lastname}
                                        name="lastname"
                                        onChange={handleFieldChange}
                                        value={consentForm.lastname}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="site"
                                        label={translatedText.label_site}
                                        name="site"
                                        onChange={handleFieldChange}
                                        value={consentForm.site}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormControl required variant="standard" style={{width: '50%', paddingRight: '2%'}}>
                                        <InputLabel id="demo-simple-select-standard-label">{translatedText.label_specialty}</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            label={translatedText.label_specialty}
                                            onChange={handleSpecialtyChange}
                                            value={consentForm.specialty}
                                        >
                                            {translatedText.list_specialties && translatedText.list_specialties.map((specialty: string) => (
                                                <MenuItem key={specialty} value={specialty}>
                                                    {specialty}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <TextField
                                        disabled={consentForm.specialty !== translatedText.specialty_other}
                                        style={{width: '50%'}}
                                        required
                                        id="specialty_other"
                                        label={translatedText.label_other_specialty}
                                        name="specialty_other"
                                        onChange={handleFieldChange}
                                        value={consentForm.specialty_other}
                                    />
                                </Grid>
                                </React.Fragment>
                                : ''
                        }
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label={translatedText.label_primary_email}
                                onChange={handleEmailChange}
                                error={emailPrimaryError}
                                name="email_primary"
                                value={consentForm.email_primary}
                                helperText={emailPrimaryError ? !consentForm.email_primary ? "Mandatory field": "Wrong format" : ""}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="email"
                                label={translatedText.label_second_email}
                                name="email_second"
                                onChange={handleEmailChange}
                                error={emailSecondError}
                                value={consentForm.email_second}
                                helperText={emailSecondError ? "Wrong format" : ""}
                            />
                        </Grid>
                        <Grid item xs={12} className={consentForm.country !== '3' ? classes.hidden : ""}>
                            <TextField
                                fullWidth
                                id="phone"
                                label={translatedText.label_phone}
                                name="phone"
                                onChange={handleFieldChange}
                                autoComplete="phone"
                                value={consentForm.phone}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="mobile"
                                label={translatedText.label_mobile}
                                name="mobile"
                                onChange={handleFieldChange}
                                value={consentForm.mobile}
                            />
                        </Grid>
                        <span>
                            {translatedText.required_text}
                        </span>
                        <Grid item xs={12}>
                            <MoreInfo country={parseInt(consentForm.country)}></MoreInfo>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox required onChange={handleCheckBoxChange} value="consent" color="primary" />}
                                label={translatedText.consent}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox required onChange={handleCheckBoxChange} value="tos" color="primary" />}
                                label={
                                    <div>
                                        <span>{translatedText.privacy_policy} <PrivacyPolicy country={parseInt(consentForm.country)}></PrivacyPolicy></span>

                                    </div>}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        className={classes.submit}
                        disabled={disableSubmit}
                    >
                        {translatedText.label_submit}
                    </Button>
                </Form>
                <Typography component="h2" variant="h6">
                    {confirmationText.line1}
                    <br/>
                    <br/>
                    {confirmationText.line2}
                    <br/>
                    {confirmationText.line3}
                </Typography>
            </div>
            <Box mt={5}>
                <Copyright />
            </Box>
        </Container>
    );
}
